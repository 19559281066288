import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import {
  getParticipantsCount,
  getAccessCodesListExcel,
  getAllResultsInCombinedPDF,
  getAllResultsInSeparatePDFs,
  getUsedTANsList,
  deleteProject,
  updateProject,
  getStudentProfilesPDFZip
} from '../../service';
import { downloadFile, combinePDFsFromURLs, downloadPDFsFromURLAsZip } from '../../utils/download';

import { AdminContext } from '../../contexts/admin.context';

import AddParticipants from './AddParticipants';
import AccessCodeForm from '../AccessCodeForm';

import Spinner from '../common/Spinner';
import Button from '../common/Button';
import { DownloadButton } from '../common/DownloadButton';
import ClipboardCopyField from '../common/ClipboardCopyField';
import { DeleteIcon, PLusIconWithBorder } from '../icons';
import { getTranslation } from '../../utils/translation';

const ProjectCard = ({ project, serviceId, classes, setClasses }) => {
  const { t, i18n } = useTranslation();

  const {
    themeId,
    teamType,
    setContentPopupInfo,
    themeConfig: { primary, secondary, buttonRadius } = {},
    portalConfig: { classDownloadsConfig, projectTypes } = {}
  } = useContext(AdminContext);

  const [buttonLoadingStates, setButtonLoadingStates] = useState({
    projectType: false,
    allAccessCodes: false,
    usedAccessCodes: false,
    vipOne: false,
    vipSeparate: false,
    strengthOne: false,
    strengthSeparate: false,
    rolesOne: false,
    rolesSeparate: false,
    studentOne: false,
    studentSeparate: false
  });
  const [participantsCount, setParticipantsCount] = useState(0);
  const [downloadingParticipants, setDownloadingParticipants] = useState(true);
  const [vipUrl, setVipUrl] = useState('');
  const [classWebpageLink, setClassWebpageLink] = useState('');
  const [downloadsConfig, setDownloadsConfig] = useState({});
  const [enableStudentPDFDownloads, setEnableStudentPDFDownloads] = useState({
    type: 'one',
    tans: [],
    pages: ['1 - 10']
  });

  // Handle access codes download
  const handleDownloadsAccessCodes = async (type) => {
    const loadingKey = type === 'all' ? 'allAccessCodes' : 'usedAccessCodes';

    try {
      setButtonLoadingStates((prev) => ({ ...prev, [loadingKey]: true }));

      const { id: pid, accountId: aid } = project;
      const response = await getAccessCodesListExcel({
        pid,
        aid,
        lang: i18n.language
      });

      if (response.status === 200) {
        const fileName = type === 'all' ? 'AccessCodes.xlsx' : 'UsedAccessCodes.xlsx';

        downloadFile(response.data, fileName);
        return;
      }
      toast.error(t('errors.failed-download-code'));
    } catch (e) {
      toast.error(t('errors.failed-download-code'));
      console.error('Failed to download access codes:', e);
    } finally {
      setButtonLoadingStates((prev) => ({ ...prev, [loadingKey]: false }));
    }
  };

  /**
   * Download profiles and handle loading states and error messaging.
   *
   * @param {String} loadingKey - The key to identify loading states.
   * @param {String} fileSuffix - The file extension for the downloaded file.
   * @param {Function} downloadFunction - The function to download the file.
   * @param {Object} additionalParams - Additional parameters for the download function.
   */
  const handleProfileDownload = async (
    loadingKey,
    fileSuffix,
    downloadFunction,
    additionalParams = {}
  ) => {
    const { id: pid, accountId: aid } = project;
    const { tpl } = additionalParams; // Extract tpl from additionalParams

    try {
      // Set the loading state for the button
      setButtonLoadingStates((prev) => ({ ...prev, [loadingKey]: true }));

      // Download the file
      // Pass only tpl from additionalParams if it exists
      const response = await downloadFunction({
        aid,
        pid,
        ...(tpl && { tpl })
      });

      // If successful, download the file to the client
      if (response.status === 200) {
        downloadFile(response.data, `${additionalParams.title}.${fileSuffix}`);
      }
    } catch (error) {
      // Display a generic error message to the user
      toast.error(getTranslation(t, 'error.failedToDownload') ?? `Failed to download`);

      // Log the specific error for debugging purposes
      console.error(`Failed to download due to: ${error.message || 'unknown error'}`);
    } finally {
      // Reset the loading state for the button
      setButtonLoadingStates((prev) => ({ ...prev, [loadingKey]: false }));
    }
  };

  /**
   * Handle the download of various profile types.
   *
   * @param {String} profileType - The type of profile to download.
   * @param {String} downloadType - The type of download, either 'one' or not 'one'.
   */
  const handleProfilesDownload = async (profileType, downloadType) => {
    // Warn and return if the profileType is unknown
    if (!downloadsConfig[profileType]) {
      console.warn(`Unknown profileType: ${profileType}`);
      return;
    }

    const isDownloadTypeOne = downloadType === 'one';
    const loadingKey = `${profileType}${isDownloadTypeOne ? 'One' : 'Separate'}`;
    const fileSuffix = isDownloadTypeOne ? 'pdf' : 'zip';
    const downloadFunction = isDownloadTypeOne
      ? getAllResultsInCombinedPDF
      : getAllResultsInSeparatePDFs;

    handleProfileDownload(loadingKey, fileSuffix, downloadFunction, {
      ...downloadsConfig[profileType]
    });
  };

  // Handle student profile download
  const handleStudentProfilePDFDownload = async (type) => {
    // set loader
    const loadingKey = type === 'one' ? 'studentOne' : 'studentSeparate';
    setButtonLoadingStates((prev) => ({ ...prev, [loadingKey]: true }));
    setEnableStudentPDFDownloads({ type, tans: [], pages: ['1 - 10'] });

    try {
      const { id: pid } = project;
      // get all used tans
      const { data: usedTansList } = await getUsedTANsList(pid);

      if (!usedTansList || !usedTansList.length) {
        toast.info("Aucun TAN utilisé n'a été trouvé");
        return;
      }

      const downloadPages = Math.ceil(usedTansList.length / 10);

      setEnableStudentPDFDownloads({
        type,
        tans: usedTansList,
        pages: Array.from({ length: downloadPages }, (_, i) => `${i * 10 + 1} - ${i * 10 + 10}`)
      });
    } catch (error) {
      toast.error('Échec du téléchargement du profil pdf');
      console.error('Profile Downloading Error: ', error);
    } finally {
      setButtonLoadingStates((prev) => ({
        ...prev,
        [loadingKey]: false
      }));
    }
  };

  // Download blocks of PDFs
  const downloadBlocksOfPDFs = async (loadingKey, page) => {
    try {
      setButtonLoadingStates((prev) => ({
        ...prev,
        [loadingKey]: true
      }));

      const { id: pid } = project;
      const { type, tans } = enableStudentPDFDownloads;

      const usedTansList = tans.slice(page * 10, (page + 1) * 10);

      const profilePDFURLs = [];

      // Prepare pdf urls
      for (const { tan, accountId, projectId } of usedTansList) {
        const userProfileId = `${themeId?.toLowerCase()?.trim()}/${teamType
          ?.toLowerCase()
          ?.trim()}/${accountId}/${projectId}/student-pdf/${tan}`;

        let pdfPageUrl = `https://www.next.mission-future.com/${userProfileId}`;

        if (project?.specs?.projectType !== 'default') pdfPageUrl += '?withProfessionMap=true';

        profilePDFURLs.push({ url: pdfPageUrl, id: userProfileId.split('/').join('-') });
      }

      const { data: { URL } = {} } = await getStudentProfilesPDFZip({
        studentProfiles: profilePDFURLs
      });

      let zipURL = URL;

      try {
        zipURL = JSON.parse(URL);
      } catch (e) {
        console.log('not json', e);
      }

      console.log('Zip URL', zipURL);

      const updateDownloadPercentage = (percentCompleted) => {
        setButtonLoadingStates((prev) => ({
          ...prev,
          [loadingKey]: percentCompleted
        }));
      };

      if (type === 'one') {
        await combinePDFsFromURLs(
          zipURL,
          `Combined-Student-Profiles-${pid}-${page + 1}-${(page + 1) * 10}`,
          updateDownloadPercentage
        );
      } else {
        await downloadPDFsFromURLAsZip(
          zipURL,
          `Student-Profiles-Archive-${pid}-${page}`,
          updateDownloadPercentage
        );
      }
    } catch (error) {
      toast.error('Échec du téléchargement du profil pdf');
      console.error('Profile Downloading Error: ', error);
    } finally {
      setButtonLoadingStates((prev) => ({
        ...prev,
        [loadingKey]: false
      }));
    }
  };

  const handleParticipantAdd = (newParticipantsCount) => {
    const updatedParticipantsCount = parseInt(participantsCount) + parseInt(newParticipantsCount);
    setParticipantsCount(updatedParticipantsCount);
  };

  const showAddParticipantPopup = () => {
    setContentPopupInfo({
      isVisible: true,
      content: <AddParticipants projectId={project.id} onAdd={handleParticipantAdd} />
    });
  };

  const showAccessCodeForm = () => {
    setContentPopupInfo({
      isVisible: true,
      content: (
        <AccessCodeForm projectId={project.id} accessCodeTpl={downloadsConfig?.accessCodes} />
      )
    });
  };

  // Get class details
  useEffect(() => {
    if (!project) return;
    const fetchParticipantsCount = async () => {
      try {
        if (serviceId === 'participants') {
          const response = await getParticipantsCount(project.id);
          const { count } = response.data;
          setParticipantsCount(count);
        }

        // Class webpage link
        if (project) {
          const { id: projectId, accountId } = project;
          const link = `https://www.mission-future.com/${themeId}/${teamType}/${accountId}/${projectId}`;
          setClassWebpageLink(link);
        }

        // Set downloads config
        if (classDownloadsConfig) {
          setDownloadsConfig({
            vip: {
              title: classDownloadsConfig?.profilePdfDownloadsConfigVipTitle
            },
            strength: {
              title: classDownloadsConfig?.profilePdfDownloadsConfigStrengthTitle,
              tpl: classDownloadsConfig?.profilePdfDownloadsConfigStrengthTpl
            },
            roles: {
              title: classDownloadsConfig?.profilePdfDownloadsConfigRolesTitle,
              tpl: classDownloadsConfig?.profilePdfDownloadsConfigRolesTpl
            },
            accessCodes: classDownloadsConfig?.pdfTemplateNamesAccessCodes
          });
        }
      } catch (error) {
        console.error('ERROR', error);
        toast.error(t('errors.failed-load-class-details'));
      } finally {
        setDownloadingParticipants(false);
      }
    };

    fetchParticipantsCount();
  }, [project]);

  useEffect(() => {
    // VIP URL
    if (project?.specs?.url) {
      setVipUrl(project.specs.url + `?lang=${i18n.language}`);
    }
  }, [project, i18n.language]);

  // console.log('Project Details ---------------');
  // console.log('Name', project.specs.description);
  // console.log('Created', project.created);
  // console.log('Participants', participantsCount); // Update button action: showAddParticipantPopup
  // console.log('VIP URL', vipUrl); // Update button action: showAccessCodeForm
  // console.log('Class webpage link', classWebpageLink);
  // console.log('Downloads config', downloadsConfig);
  // console.log('-----------------------------');

  return (
    <>
      {serviceId === 'participants' && (
        <>
          {!downloadingParticipants && (
            <div className="flex gap-2 items-center">
              <p className="text-base py-2 bg-[#F6F5F5] rounded-full text-[#606060] pl-4 w-16">
                {participantsCount}
              </p>
              <button
                className="flex items-center justify-center bg-primary text-white rounded-full w-8 h-8"
                onClick={showAddParticipantPopup}>
                <PLusIconWithBorder className="w-4 h-4" />
              </button>
            </div>
          )}
          {downloadingParticipants && <Spinner />}
        </>
      )}

      {serviceId === 'downloadAccessCodes' && (
        <DownloadButton
          onClick={() => handleDownloadsAccessCodes('all')}
          isLoading={buttonLoadingStates.allAccessCodes}>
          <p className="text-base">{getTranslation(t, `class.table.${serviceId}.btn`)}</p>
        </DownloadButton>
      )}

      {serviceId === 'downloadUsedAccessCodes' && (
        <DownloadButton
          onClick={() => handleDownloadsAccessCodes('used')}
          isLoading={buttonLoadingStates.usedAccessCodes}>
          <p className="text-base">{getTranslation(t, `class.table.${serviceId}.btn`)}</p>
        </DownloadButton>
      )}

      {serviceId === 'classWebpage' && <ClipboardCopyField textToCopy={classWebpageLink} />}

      {serviceId === 'vipTestLink' && <ClipboardCopyField textToCopy={vipUrl} />}

      {serviceId === 'downloadVipProfiles' && (
        <div className="flex gap-2">
          <DownloadButton
            onClick={() => handleProfilesDownload('vip', 'one')}
            isLoading={buttonLoadingStates.vipOne}
            buttonType={'primary'}>
            {getTranslation(t, `class.table.${serviceId}.btn.one`)}
          </DownloadButton>
          <DownloadButton
            onClick={() => handleProfilesDownload('vip', 'separate')}
            isLoading={buttonLoadingStates.vipSeparate}
            buttonType={'outline'}>
            {getTranslation(t, `class.table.${serviceId}.btn.separate`)}
          </DownloadButton>
        </div>
      )}

      {serviceId === 'downloadStrengthProfiles' && (
        <div className="flex gap-2">
          <DownloadButton
            onClick={() => handleProfilesDownload('strength', 'one')}
            isLoading={buttonLoadingStates.strengthOne}
            buttonType={'primary'}>
            {getTranslation(t, `class.table.${serviceId}.btn.one`)}
          </DownloadButton>
          <DownloadButton
            onClick={() => handleProfilesDownload('strength', 'separate')}
            isLoading={buttonLoadingStates.strengthSeparate}
            buttonType={'outline'}>
            {getTranslation(t, `class.table.${serviceId}.btn.separate`)}
          </DownloadButton>
        </div>
      )}

      {serviceId === 'downloadProfessionalRoleProfiles' && (
        <div className="flex gap-2">
          <DownloadButton
            onClick={() => handleProfilesDownload('roles', 'one')}
            isLoading={buttonLoadingStates.rolesOne}
            buttonType={'primary'}>
            {getTranslation(t, `class.table.${serviceId}.btn.one`)}
          </DownloadButton>
          <DownloadButton
            onClick={() => handleProfilesDownload('roles', 'separate')}
            isLoading={buttonLoadingStates.rolesSeparate}
            buttonType={'outline'}>
            {getTranslation(t, `class.table.${serviceId}.btn.separate`)}
          </DownloadButton>
        </div>
      )}

      {serviceId === 'downloadStudentProfiles' && (
        <>
          <div className="flex gap-2">
            <DownloadButton
              onClick={() => handleStudentProfilePDFDownload('one')}
              isLoading={buttonLoadingStates.studentOne}
              buttonType={'primary'}>
              {getTranslation(t, `class.table.${serviceId}.btn.one`)}
            </DownloadButton>
            <DownloadButton
              onClick={() => handleStudentProfilePDFDownload('separate')}
              isLoading={buttonLoadingStates.studentSeparate}
              buttonType={'outline'}>
              {getTranslation(t, `class.table.${serviceId}.btn.separate`)}
            </DownloadButton>
          </div>
          {enableStudentPDFDownloads.tans.length > 0 && (
            <div className="flex gap-2 mt-2 w-full">
              {enableStudentPDFDownloads?.pages.map((page, index) => (
                <DownloadButton
                  key={index}
                  onClick={() => downloadBlocksOfPDFs(`studentPFDownloadBlock-${page}`, index)}
                  isLoading={buttonLoadingStates?.[`studentPFDownloadBlock-${page}`]}
                  buttonType={'outline'}>
                  {page}
                </DownloadButton>
              ))}
            </div>
          )}
        </>
      )}

      {serviceId === 'profileConverter' && (
        <Button onClick={() => showAccessCodeForm()} buttonType={'primary'}>
          {getTranslation(t, `class.table.${serviceId}.btn.one`)}
        </Button>
      )}

      {serviceId === 'delete' && (
        <span
          className="flex w-full justify-center cursor-pointer"
          onClick={() => {
            Swal.fire({
              title: getTranslation(t, `class.table.${serviceId}.popup.title`),
              text: getTranslation(t, `class.table.${serviceId}.popup.text`),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: getTranslation(t, `class.table.${serviceId}.popup.confirm`),
              cancelButtonText: getTranslation(t, `class.table.${serviceId}.popup.cancel`),
              iconColor: primary,
              confirmButtonColor: primary,
              cancelButtonColor: secondary,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                const cancelButton = Swal.getCancelButton();
                // Set the border-radius directly
                if (confirmButton) confirmButton.style.borderRadius = buttonRadius;
                if (cancelButton) cancelButton.style.borderRadius = buttonRadius;
              }
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteProject(project.id);

                  setClasses(classes.filter((classItem) => classItem.id !== project.id));

                  toast.success(getTranslation(t, `class.table.${serviceId}.success`));
                } catch (error) {
                  console.log(error);
                  toast.error(getTranslation(t, `class.table.${serviceId}.fail`));
                }
              }
            });
          }}>
          <Button buttonType={'customBG'}>
            <DeleteIcon className="w-4 h-4" />
          </Button>
        </span>
      )}

      {serviceId === 'projectType' && (
        <>
          {buttonLoadingStates?.projectType ? (
            <Spinner />
          ) : (
            <select
              className={`px-2 py-1.5 rounded text-gray-primary text-sm bg-gray-100 w-48`}
              style={{ borderRadius: buttonRadius }}
              id="projectType"
              name="projectType"
              defaultValue={project?.specs?.projectType || projectTypes?.[0]?.id}
              onChange={async (e) => {
                try {
                  setButtonLoadingStates({ ...buttonLoadingStates, projectType: true });

                  const updatedValue = e.target.value;
                  const updatedProject = {
                    ...project,
                    specs: { ...project.specs, projectType: updatedValue, teamType: teamType }
                  };

                  const projectValues = {
                    pid: updatedProject.id,
                    specs: {
                      ...updatedProject.specs
                    }
                  };

                  await updateProject(projectValues);

                  const updatedClasses = classes.map((classItem) => {
                    if (classItem.id === updatedProject.id) {
                      return updatedProject;
                    }
                    return classItem;
                  });

                  setClasses(updatedClasses);
                } catch (error) {
                  toast.error('Échec de la mise à jour du type de projet');
                } finally {
                  setButtonLoadingStates({ ...buttonLoadingStates, projectType: false });
                }
              }}>
              {projectTypes.map(({ id, title }, index) => (
                <option key={index} value={id}>
                  {title?.[i18n.language] || title?.en || title?.de || title?.fr || title?.it}
                </option>
              ))}
            </select>
          )}
        </>
      )}
    </>
  );
};

export default ProjectCard;
